export const browserDebounce = {
    methods: {
        debounce(func, timeout) {
            let timer;

            return (...args) => {
                clearTimeout(timer);
                timer = setTimeout(() => func.apply(this, args), timeout);
            }
        },
    }
}