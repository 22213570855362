/**
 * Creates and scales a canvas image for various purposes (print-ready, editor preview, or consumer preview).
 * Handles different modes with encapsulated logic for scaling, cloning, and exporting.
 * The function clones objects from a provided cache ('front' or 'back'), scales them to fit the specified dimensions,
 * and generates either a blob or a base64-encoded image with optional DPI metadata.
 *
 * @param {Object} fabricInstance - The Fabric.js instance to use for creating and manipulating canvases.
 * @param {Array} cache - The cache of canvas objects to clone (either frontCache or backCache).
 * @param {Object} productPrintAreaDimensions - Object containing dimensions for different purposes.
 * @param {string} mode - Specifies the purpose ("print", "editorPreview", or "consumerPreview").
 * @param {string} outputFormat - Specifies the output format ('blob' or 'base64').
 * @param {Array} productDesignObjects - Specifies an array with design objects to be used on the storefront.
 * @param {Object} customTextOriginalCoordinates - Coordinates and other data of the original custom text object, if any.
 * @returns {Promise<Object>} A promise that resolves to the generated image in the specified format.
 * @throws {Error} If an invalid mode or output format is specified.
 */
export async function createPreviewOrPrintReadyCanvasBlobOrBase64(
    fabricInstance,
    cache,
    productPrintAreaDimensions,
    mode, // "editorPrint", "editorPreview", "consumerPrint", "consumerPreview"
    outputFormat = "blob",
    productDesignObjects = [],
    customTextOriginalCoordinates = null
) {
    // Validate the mode
    if (!["editorPrint", "editorPreview", "consumerPrint", "consumerPreview"].includes(mode)) {
        throw new Error("Invalid mode specified. Choose 'editorPrint', 'editorPreview', 'consumerPrint', or 'consumerPreview'.");
    }

    if (outputFormat !== "blob" && outputFormat !== "base64") {
        throw new Error("Invalid output format specified. Choose 'blob' or 'base64'.");
    }

    if ((mode === "consumerPrint" || mode === "consumerPreview") && productDesignObjects.length === 0) {
        throw new Error("Modes 'consumerPrint' and 'consumerPreview' require a productDesignObjects array with at least one element.");
    }

    // Helper: Get canvas dimensions for the specified mode.
    function getCanvasDimensions() {
        switch (mode) {
            case "editorPrint":
            case "consumerPrint":
                return {
                    height: productPrintAreaDimensions.heightPrintPixels,
                    width: productPrintAreaDimensions.widthPrintPixels,
                };
            case "editorPreview":
                return {
                    height: productPrintAreaDimensions.heightConverted,
                    width: productPrintAreaDimensions.widthConverted,
                };
            case "consumerPreview":
                return {
                    height: productPrintAreaDimensions.heightPixels,
                    width: productPrintAreaDimensions.widthPixels,
                };
            default:
                throw new Error("Invalid mode specified.");
        }
    }

    // Helper: Create a new canvas with the specified dimensions.
    function createCanvas(dimensions) {
        const canvas = new fabricInstance.Canvas("desired-canvas");
        canvas.setHeight(dimensions.height);
        canvas.setWidth(dimensions.width);

        // Ensure the canvas element ignores devicePixelRatio scaling.
        const element = canvas.getElement();
        element.height = dimensions.height;
        element.width = dimensions.width;
        // Ensure no zoom in/out scaling is applied.
        canvas.setZoom(1);

        return canvas;
    }

    // Helper: Clone objects from the cache to avoid soft-copy issues, await until they're cloned.
    async function cloneObjects() {
        return await Promise.all(
            cache.map(canvasObject => {
                return new Promise((resolve, reject) => {
                    try {
                        if (canvasObject.customisable) {
                            if (customTextOriginalCoordinates && canvasObject.textAlign === "left") {
                                canvasObject.left = customTextOriginalCoordinates.left;
                                canvasObject.top = customTextOriginalCoordinates.top;
                            } else if (customTextOriginalCoordinates && canvasObject.textAlign === "right") {
                                canvasObject.left = (customTextOriginalCoordinates.right - canvasObject.width) * canvasObject.scaleX;
                                // canvasObject.left = (customTextOriginalCoordinates.left + customTextOriginalCoordinates.width) - canvasObject.width * canvasObject.scaleX;
                                canvasObject.top = customTextOriginalCoordinates.top;
                            }
                        }
                        canvasObject.clone(clonedObject => resolve(clonedObject));
                    } catch (error) {
                        reject(error);
                    }
                });
            })
        );
    }

    // Helper: Export the canvas as a Blob or Base64 string.
    async function exportCanvas(canvas, selectedOutputFormat, setDPI = false) {
        const desiredCanvasAsBlob = await createBlobFromCanvas(canvas.getElement());
        if (setDPI) {
            const buffer = await desiredCanvasAsBlob.arrayBuffer();
            const uint8Array = new Uint8Array(buffer);
            const ppmX = 11811; // Sets DPI to 300.
            const ppmY = 11811;
            const updatedBlobData = addOrModifypHYsChunk(uint8Array, ppmX, ppmY);
            // downloadBlob(updatedBlobData ? new Blob([updatedBlobData], { type: 'image/png' }) : desiredCanvasAsBlob);
            return selectedOutputFormat === "blob"
                ? new Blob([updatedBlobData], { type: "image/png" })
                : await blobToBase64(new Blob([updatedBlobData], { type: "image/png" }));
        }
        // downloadBlob(desiredCanvasAsBlob);
        return selectedOutputFormat === "blob" ? desiredCanvasAsBlob : await blobToBase64(desiredCanvasAsBlob);
    }

    const processedDesignObjects = [];

    if (productDesignObjects) {
        for (let i = 0; i < productDesignObjects.length; i++) {

            if (productDesignObjects[i].type === "Image") {
                // We'll identify images based on the filename they've been saved with.
                const filename = productDesignObjects[i].image_resized;
                processedDesignObjects.push({
                    type: "image",
                    filename: filename,
                    width: productDesignObjects[i].scaledWidth,
                    height: productDesignObjects[i].scaledHeight
                });
            } else if (productDesignObjects[i].type === "Text" && productDesignObjects[i].customisable === 1) {
                // We'll identify custom text by the customisable property, since the text property will change.
                processedDesignObjects.push({
                    type: "customText",
                    customisable: productDesignObjects[i].customisable,
                    text: productDesignObjects[i].text,
                    scaleX: productDesignObjects[i].scaleX,
                    scaleY: productDesignObjects[i].scaleY,
                });
            } else if (productDesignObjects[i].type === "Text" && productDesignObjects[i].customisable === null) {
                // We'll identify static text by its content, since it will not change.
                processedDesignObjects.push({
                    type: "staticText",
                    text: productDesignObjects[i].text,
                    scaleX: productDesignObjects[i].scaleX,
                    scaleY: productDesignObjects[i].scaleY,
                });
            }

        }
    }

    // Main Logic.
    const desiredCanvasDimensions = getCanvasDimensions();
    const desiredCanvas = createCanvas(desiredCanvasDimensions);

    // To keep the order, add all objects to the new canvas after they're finished cloning.
    const clones = await cloneObjects();
    clones.forEach(clonedObject => {
        desiredCanvas.add(clonedObject);
    });

    // Scaling and coordinate operations from here on down.
    // ***
    const scaleFactor = 1000000; // Use a large scale factor to maintain precision.
    const scaledNumerator = desiredCanvasDimensions.width * scaleFactor;
    const scaledDenominator = productPrintAreaDimensions.widthConverted * scaleFactor;

    // Calculate scaleValue as a floating-point number.
    const scaleValue = scaledNumerator / scaledDenominator;

    const canvasObjects = desiredCanvas.getObjects();

    if (mode === "consumerPrint") {
        for (let i = 0; i < canvasObjects.length; i++) {
            let scaleOfSpecificObject = scaleValue; // Initialize with a default value.

            if (canvasObjects[i].type === "image") {
                // The order of objects in processedDesignObjects should match exactly the order of the ones in canvasObjects.
                if (processedDesignObjects[i].filename == canvasObjects[i].src.replace(/^https?:\/\/[^/]+\/?/, '')) {
                    const originalImageURL = canvasObjects[i].src.replace("_resized", "");

                    // Wait for original images to load for a high-quality print.
                    const imageResizedToOriginal = await loadFabricImage(originalImageURL, fabricInstance);
                    imageResizedToOriginal.left = canvasObjects[i].left;
                    imageResizedToOriginal.top = canvasObjects[i].top;
                    // TODO: Determine if this affects off-screen canvases.
                    // imageResizedToOriginal.scaleToWidth(canvasObjects[i].width);

                    // Remove old image and insert new one at the same index.
                    desiredCanvas.remove(canvasObjects[i]);
                    canvasObjects[i] = imageResizedToOriginal;
                    desiredCanvas.insertAt(imageResizedToOriginal, i);

                    scaleOfSpecificObject = processedDesignObjects[i].width / canvasObjects[i].width;
                } else {
                    throw new Error("Canvas image element does not exist or is not in the correct order.");
                }
            } else if (canvasObjects[i].type === "i-text") {
                if (processedDesignObjects[i].type === "staticText" || processedDesignObjects[i].type === "customText") {
                    scaleOfSpecificObject = processedDesignObjects[i].scaleX;
                    // console.log(scaleOfSpecificObject);
                } else {
                    throw new Error("Canvas text element does not exist or is not in the correct order.");
                }
            }

            canvasObjects[i].scaleX = scaleOfSpecificObject;
            canvasObjects[i].scaleY = scaleOfSpecificObject;
            canvasObjects[i].left *= scaleValue;
            canvasObjects[i].top *= scaleValue;
            canvasObjects[i].setCoords();
        }
    } else if (mode === "consumerPreview") {
        for (let i = 0; i < canvasObjects.length; i++) {
            let scaleOfSpecificObject = scaleValue; // Initialize with a default value.

            if (canvasObjects[i].type === "image") {
                // The order of objects in processedDesignObjects should match exactly the order of the ones in canvasObjects.
                if (processedDesignObjects[i].filename == canvasObjects[i].src.replace(/^https?:\/\/[^/]+\/?/, '')) {
                    scaleOfSpecificObject = (processedDesignObjects[i].width * (desiredCanvasDimensions.width / productPrintAreaDimensions.widthPrintPixels)) / canvasObjects[i].width;
                } else {
                    throw new Error("Canvas image element does not exist or is not in the correct order.");
                }
            } else if (canvasObjects[i].type === "i-text") {
                if (processedDesignObjects[i].type === "staticText" || processedDesignObjects[i].type === "customText") {
                    scaleOfSpecificObject = (processedDesignObjects[i].scaleX * (desiredCanvasDimensions.width / productPrintAreaDimensions.widthPrintPixels));
                    // console.log(desiredScale);
                } else {
                    throw new Error("Canvas text element does not exist or is not in the correct order.");
                }
            }
            canvasObjects[i].scaleX = scaleOfSpecificObject;
            canvasObjects[i].scaleY = scaleOfSpecificObject;
            canvasObjects[i].left *= scaleValue;
            canvasObjects[i].top *= scaleValue;
            canvasObjects[i].setCoords();
        }
    } else if (mode === "editorPreview" || mode === "editorPrint") {
        for (let i = 0; i < canvasObjects.length; i++) {
            canvasObjects[i].strokeWidth = 0;
            canvasObjects[i].scaleX *= scaleValue;
            canvasObjects[i].scaleY *= scaleValue;
            canvasObjects[i].left *= scaleValue;
            canvasObjects[i].top *= scaleValue;
            canvasObjects[i].setCoords();
        }
    }

    desiredCanvas.renderAll();

    return await exportCanvas(desiredCanvas, outputFormat, mode === "editorPrint" || mode === "consumerPrint");
};

/**
 * Checks if an image (Blob or Base64) is fully transparent.
 *
 * @param {Blob|string} imageInput - The image blob or base64 string to check.
 * @returns {Promise<boolean>} Resolves to true if the image represents a fully transparent image, false otherwise.
 */
export async function isImageFullyTransparent(imageInput) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        let url;

        // Determine the source of the image.
        if (imageInput instanceof Blob) {
            // Convert the blob to an object URL.
            url = URL.createObjectURL(imageInput);
        } else if (typeof imageInput === "string" && imageInput.startsWith("data:image")) {
            // Use base64 string directly as the source.
            url = imageInput;
        } else {
            reject(new Error("Invalid input: must be a Blob or a Base64 string"));
            return;
        }

        img.onload = () => {
            // Create a canvas and set its dimensions to match the image.
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext("2d");
            if (!ctx) {
                // Release the object URL if it was created.
                if (imageInput instanceof Blob) URL.revokeObjectURL(url);
                reject(new Error("Failed to get 2D context"));
                return;
            }

            // Draw the image onto the canvas.
            ctx.drawImage(img, 0, 0);

            // Get the pixel data.
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const pixels = imageData.data;

            // Release the object URL if it was created.
            if (imageInput instanceof Blob) URL.revokeObjectURL(url);

            // Check the alpha channel of each pixel.
            for (let i = 3; i < pixels.length; i += 4) {
                if (pixels[i] !== 0) {
                    resolve(false); // Found a non-transparent pixel.
                    return;
                }
            }

            resolve(true); // All pixels are transparent.
        };

        img.onerror = (error) => {
            // Release the object URL if it was created.
            if (imageInput instanceof Blob) URL.revokeObjectURL(url);
            reject(error);
        };

        // Set the source of the image.
        img.src = url;
    });
};

/**
 * Wraps loading an image into a Fabric.js instance from a given URL into a Promise to be used with await.
 *
 * @param {string} url - The URL of the image to load.
 * @param {typeof fabric} fabricInstance - The Fabric.js instance.
 * @returns {Promise<fabric.Image>} A promise that resolves with the loaded Fabric.js image object.
 */
function loadFabricImage(url, fabricInstance) {
    return new Promise((resolve) => {
        fabricInstance.Image.fromURL(url, (img) => resolve(img));
    });
}

/**
 * Converts a canvas element into a Blob object.
 *
 * @param {HTMLCanvasElement} canvas - The canvas element to convert to a Blob.
 * @param {string} [type='image/png'] - The MIME type of the resulting Blob (e.g., 'image/png' or 'image/jpeg').
 * @param {number} [quality=1.0] - The quality level for the resulting image (0.0 to 1.0), used for lossy formats like JPEG.
 * @returns {Promise<Blob>} A promise that resolves to a Blob representing the canvas content.
 */
export async function createBlobFromCanvas(canvas, type = 'image/png', quality = 1.0) {
    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (blob) {
                resolve(blob);
            } else {
                reject(new Error("Blob creation failed"));
            }
        }, type, quality);
    });
};

/**
 * Converts a Blob object into a base64-encoded string.
 *
 * @param {Blob} blob - The Blob object to convert to a base64 string.
 * @returns {Promise<string>} A promise that resolves to the base64-encoded string representation of the Blob.
 */
export async function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

/**
 * Creates a transparent PNG Blob.
 *
 * @returns {Promise<Blob>} A promise that resolves to a Blob containing a 10x10 transparent PNG image.
 */
export async function createTransparentPngBlob() {
    // Create a 10x10 canvas.
    const canvas = document.createElement('canvas');
    canvas.width = 10;
    canvas.height = 10;
    const ctx = canvas.getContext('2d');

    // Fill the canvas with transparent pixels.
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Convert the canvas to a Blob of type 'image/png'.
    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (blob) {
                resolve(blob);
            } else {
                reject(new Error("Blob creation failed"));
            }
        }, 'image/png');
    });
};

/**
 * Downloads a Blob as a file.
 *
 * @param {Blob} blob - The Blob object to download.
 * @param {string} fileName - The name of the file to save as.
 */
export function downloadBlob(blob, fileName) {
    // Create a URL for the blob.
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element.
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;

    // Append/remove the anchor to/from the body (required for Firefox) and click.
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoke the blob URL to free memory.
    URL.revokeObjectURL(url);
};

/**
 * Converts an image URL to a Blob.
 *
 * @param {string} imageUrl - The URL of the image to convert.
 * @returns {Promise<Blob>} A promise that resolves to a Blob containing the image data.
 */
export async function imageUrlToBlob(imageUrl) {
    try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error('Failed to fetch image');
        }
        const blob = await response.blob();
        return blob;
    } catch (error) {
        console.error('Error converting image to blob:', error);
    }
};

/**
 * Estimates the approximate size in bytes of an object or variable.
 *
 * @param {Object} object - The object or variable to measure.
 * @returns {number} An approximate size of the object in bytes.
 */
export function roughSizeOfObject(object) {
    const objectList = new Set();
    const stack = [object];
    let bytes = 0;

    while (stack.length) {
        const value = stack.pop();

        if (typeof value === 'boolean') {
            bytes += 4;
        } else if (typeof value === 'string') {
            bytes += value.length * 2;
        } else if (typeof value === 'number') {
            bytes += 8;
        } else if (value instanceof Blob) {
            bytes += value.size; // Handle Blob size
        } else if (typeof value === 'object' && value !== null) {
            if (objectList.has(value)) {
                continue;
            }
            objectList.add(value);

            for (const i in value) {
                if (value.hasOwnProperty(i)) {
                    stack.push(value[i]);
                }
            }
        }
    }
    return bytes;
};

/**
 * Adds or modifies the `pHYs` chunk in a PNG image.
 * If a `pHYs` chunk is not found, it inserts one right before the first `IDAT` chunk.
 * This chunk defines the pixel dimensions in terms of pixels per meter.
 *
 * @param {Uint8Array} data - The PNG data as a byte array.
 * @param {number} ppmx - Pixels per meter in the x direction.
 * @param {number} ppmy - Pixels per meter in the y direction.
 * @returns {Uint8Array} Modified PNG data with updated or inserted `pHYs` chunk.
 * @throws {Error} If the PNG signature is invalid or CRC values do not match.
 */
function addOrModifypHYsChunk(data, ppmx, ppmy) {
    // Check the first 8 bytes of the Uint8Array to ensure they match the PNG signature (89 50 4E 47 0D 0A 1A 0A).
    if (
        data[0] !== 0x89 || data[1] !== 0x50 || data[2] !== 0x4E || data[3] !== 0x47 ||
        data[4] !== 0x0D || data[5] !== 0x0A || data[6] !== 0x1A || data[7] !== 0x0A
    ) {
        throw new Error('Invalid .png file header: possibly caused by DOS-Unix line ending conversion?');
    }

    // Initialize buffers for reading and writing data.
    const uint8 = new Uint8Array(4);
    const int32 = new Int32Array(uint8.buffer);
    const uint32 = new Uint32Array(uint8.buffer);

    let index = 8; // Start right after the PNG header.

    // Traverse the PNG data to search for chunks.
    while (index < data.length) {
        // Read the length of the current chunk, stored as a Uint32.
        uint8[3] = data[index++];
        uint8[2] = data[index++];
        uint8[1] = data[index++];
        uint8[0] = data[index++];
        const length = uint32[0] + 4; // Add 4 to account for the chunk name.

        // Read the chunk name (4 bytes) to determine its type.
        const chunk = new Uint8Array(length);
        chunk[0] = data[index++];
        chunk[1] = data[index++];
        chunk[2] = data[index++];
        chunk[3] = data[index++];

        // Convert the chunk name to a string.
        const name = String.fromCharCode(chunk[0]) + String.fromCharCode(chunk[1]) +
                    String.fromCharCode(chunk[2]) + String.fromCharCode(chunk[3]);

        // Save the start index of the chunk's data for potential modifications.
        let chunkDataStart = index;

        // Read the contents of the chunk.
        for (let i = 4; i < length; i++) {
            chunk[i] = data[index++];
        }

        // Read and validate the CRC for the chunk.
        const crcStart = index;
        uint8[3] = data[index++];
        uint8[2] = data[index++];
        uint8[1] = data[index++];
        uint8[0] = data[index++];
        const crcActual = int32[0];
        const crcExpect = computeCrc32Checksum(chunk);

        // If the CRC does not match, the PNG file might be corrupted.
        if (crcExpect !== crcActual) {
            throw new Error(`CRC values for ${name} header do not match, PNG file is likely corrupted`);
        }

        // Check if the current chunk is the IDAT chunk to insert pHYs before it,
        // since if we find an IDAT chunk before a pHYs, it means a pHYs is not present.
        if (name === "IDAT") {
            // Prepare to insert the pHYs chunk before the IDAT chunk.
            chunkDataStart -= 8;
            const originalLength = data.length;

            // Create a new buffer to accommodate the additional pHYs chunk (21 extra bytes).
            const newData = new Uint8Array(originalLength + 21);

            // Copy data before the IDAT chunk.
            for (let i = 0; i < chunkDataStart; i++) {
                newData[i] = data[i];
            }

            // Copy data after the IDAT chunk.
            for (let i = chunkDataStart; i < originalLength; i++) {
                newData[i + 21] = data[i];
            }

            // Create the pHYs chunk.
            const phys = new Uint8Array(13);
            let i = 0;

            // Set the length of the pHYs chunk (9 bytes).
            int32[0] = 9;
            newData[chunkDataStart++] = uint8[3];
            newData[chunkDataStart++] = uint8[2];
            newData[chunkDataStart++] = uint8[1];
            newData[chunkDataStart++] = uint8[0];

            // Write the pHYs chunk name.
            phys[i++] = newData[chunkDataStart++] = 'p'.charCodeAt(0);
            phys[i++] = newData[chunkDataStart++] = 'H'.charCodeAt(0);
            phys[i++] = newData[chunkDataStart++] = 'Y'.charCodeAt(0);
            phys[i++] = newData[chunkDataStart++] = 's'.charCodeAt(0);

            // Set the pixels per meter values (ppmx, ppmy).
            uint32[0] = ppmx;
            phys[i++] = newData[chunkDataStart++] = uint8[3];
            phys[i++] = newData[chunkDataStart++] = uint8[2];
            phys[i++] = newData[chunkDataStart++] = uint8[1];
            phys[i++] = newData[chunkDataStart++] = uint8[0];

            uint32[0] = ppmy;
            phys[i++] = newData[chunkDataStart++] = uint8[3];
            phys[i++] = newData[chunkDataStart++] = uint8[2];
            phys[i++] = newData[chunkDataStart++] = uint8[1];
            phys[i++] = newData[chunkDataStart++] = uint8[0];

            // Specify the unit as meters.
            phys[i++] = newData[chunkDataStart++] = 1;

            // Calculate and append the CRC for the pHYs chunk.
            const physCRC = computeCrc32Checksum(phys);
            int32[0] = physCRC;

            newData[chunkDataStart++] = uint8[3];
            newData[chunkDataStart++] = uint8[2];
            newData[chunkDataStart++] = uint8[1];
            newData[chunkDataStart++] = uint8[0];

            return newData; // Return the updated PNG data with the new pHYs chunk.
        }

        // If a pHYs chunk is already present, update its values.
        if (name === "pHYs") {
            // console.log("pHYs chunk found, updating values...");

            const phys = new Uint8Array(13);
            let i = 0;

            // Write the pHYs chunk name.
            phys[i++] = 'p'.charCodeAt(0);
            phys[i++] = 'H'.charCodeAt(0);
            phys[i++] = 'Y'.charCodeAt(0);
            phys[i++] = 's'.charCodeAt(0);

            // Update the pixels per meter values (ppmx, ppmy).
            uint32[0] = ppmx;
            phys[i++] = data[chunkDataStart++] = uint8[3];
            phys[i++] = data[chunkDataStart++] = uint8[2];
            phys[i++] = data[chunkDataStart++] = uint8[1];
            phys[i++] = data[chunkDataStart++] = uint8[0];

            uint32[0] = ppmy;
            phys[i++] = data[chunkDataStart++] = uint8[3];
            phys[i++] = data[chunkDataStart++] = uint8[2];
            phys[i++] = data[chunkDataStart++] = uint8[1];
            phys[i++] = data[chunkDataStart++] = uint8[0];

            // Specify the unit as meters.
            phys[i++] = data[chunkDataStart++] = 1;

            // Calculate and update the CRC for the pHYs chunk.
            const physCRC = computeCrc32Checksum(phys);
            int32[0] = physCRC;

            data[crcStart++] = uint8[3];
            data[crcStart++] = uint8[2];
            data[crcStart++] = uint8[1];
            data[crcStart++] = uint8[0];

            return data; // Return the updated PNG data.
        }
    }
};

/**
 * Computes the CRC32 checksum of a given buffer using a seed value.
 * For buffers longer than 10,000 bytes, it falls back to an optimized
 * large-buffer implementation (computeCrc32WithLargeBufferOptimization).
 *
 * @param {Uint8Array} inputBuffer - The input buffer to calculate the CRC32 checksum for.
 * @param {number} seedValue - The seed value to initialize the CRC32 calculation.
 * @returns {number} The computed CRC32 checksum.
 */
function computeCrc32Checksum(inputBuffer, seedValue) {
    // Retrieve the CRC32 lookup table.
    const crcLookupTable = generateSignedCrc32LookupTable();

    // If the buffer length exceeds 10,000, use the large-buffer optimized implementation.
    if (inputBuffer.length > 10000) {
        return computeCrc32WithLargeBufferOptimization(inputBuffer, seedValue);
    }

    // Initialize the CRC value by XORing the seed value with -1.
    let crcAccumulator = seedValue ^ -1;

    // Calculate the loop limit for processing the buffer in chunks of 4 bytes.
    const chunkLimit = inputBuffer.length - 3;

    let byteIndex = 0;

    // Process the buffer in 4-byte chunks for performance.
    while (byteIndex < chunkLimit) {
        crcAccumulator = (crcAccumulator >>> 8) ^ crcLookupTable[(crcAccumulator ^ inputBuffer[byteIndex++]) & 0xFF];
        crcAccumulator = (crcAccumulator >>> 8) ^ crcLookupTable[(crcAccumulator ^ inputBuffer[byteIndex++]) & 0xFF];
        crcAccumulator = (crcAccumulator >>> 8) ^ crcLookupTable[(crcAccumulator ^ inputBuffer[byteIndex++]) & 0xFF];
        crcAccumulator = (crcAccumulator >>> 8) ^ crcLookupTable[(crcAccumulator ^ inputBuffer[byteIndex++]) & 0xFF];
    }

    // Handle any remaining bytes after processing 4-byte chunks.
    while (byteIndex < inputBuffer.length) {
        crcAccumulator = (crcAccumulator >>> 8) ^ crcLookupTable[(crcAccumulator ^ inputBuffer[byteIndex++]) & 0xFF];
    }

    // Finalize the CRC value by XORing it with -1 and returning the result.
    return crcAccumulator ^ -1;
};

/**
 * Computes the CRC32 checksum of a given buffer using a seed value.
 * Optimized for processing 8-byte chunks at a time for performance.
 * Uses the CRC32 lookup table generated by generateSignedCrc32LookupTable().
 *
 * @param {Uint8Array} inputBuffer - The input buffer to calculate the CRC32 checksum for.
 * @param {number} seedValue - The seed value to initialize the CRC32 calculation.
 * @returns {number} The computed CRC32 checksum.
 */
function computeCrc32WithLargeBufferOptimization(inputBuffer, seedValue) {
    // Retrieve the CRC32 lookup table.
    const crcLookupTable = generateSignedCrc32LookupTable();

    // Initialize the CRC value by XORing the seed value with -1.
    let crcAccumulator = seedValue ^ -1;

    // Calculate the loop limit for processing the buffer in chunks of 8 bytes.
    const chunkLimit = inputBuffer.length - 7;

    let byteIndex = 0;

    // Process the buffer in 8-byte chunks for better performance.
    while (byteIndex < chunkLimit) {
        crcAccumulator = (crcAccumulator >>> 8) ^ crcLookupTable[(crcAccumulator ^ inputBuffer[byteIndex++]) & 0xFF];
        crcAccumulator = (crcAccumulator >>> 8) ^ crcLookupTable[(crcAccumulator ^ inputBuffer[byteIndex++]) & 0xFF];
        crcAccumulator = (crcAccumulator >>> 8) ^ crcLookupTable[(crcAccumulator ^ inputBuffer[byteIndex++]) & 0xFF];
        crcAccumulator = (crcAccumulator >>> 8) ^ crcLookupTable[(crcAccumulator ^ inputBuffer[byteIndex++]) & 0xFF];
        crcAccumulator = (crcAccumulator >>> 8) ^ crcLookupTable[(crcAccumulator ^ inputBuffer[byteIndex++]) & 0xFF];
        crcAccumulator = (crcAccumulator >>> 8) ^ crcLookupTable[(crcAccumulator ^ inputBuffer[byteIndex++]) & 0xFF];
        crcAccumulator = (crcAccumulator >>> 8) ^ crcLookupTable[(crcAccumulator ^ inputBuffer[byteIndex++]) & 0xFF];
        crcAccumulator = (crcAccumulator >>> 8) ^ crcLookupTable[(crcAccumulator ^ inputBuffer[byteIndex++]) & 0xFF];
    }

    // Handle any remaining bytes after processing 8-byte chunks.
    while (byteIndex < inputBuffer.length) {
        crcAccumulator = (crcAccumulator >>> 8) ^ crcLookupTable[(crcAccumulator ^ inputBuffer[byteIndex++]) & 0xFF];
    }

    // Finalize the CRC value by XORing it with -1 and returning the result.
    return crcAccumulator ^ -1;
};

/**
 * Generates a CRC32 lookup table using a signed polynomial.
 * This table is used to optimize CRC32 calculations by precomputing values.
 *
 * @returns {Int32Array|Array} The generated CRC32 lookup table.
 *          Returns an Int32Array if supported, otherwise a standard array.
 */
function generateSignedCrc32LookupTable() {
    // Initialize an array to hold the CRC32 lookup values (256 entries).
    const crcTable = new Array(256);
    let currentEntry;

    // Loop through each possible byte value (0 to 255).
    for (let byte = 0; byte < 256; ++byte) {
        currentEntry = byte;

        // Process the entry using the signed polynomial over 8 iterations.
        for (let bit = 0; bit < 8; bit++) {
            const isLeastSignificantBitSet = (currentEntry & 1) !== 0;

            // If the least significant bit is set, XOR with the signed polynomial.
            currentEntry = isLeastSignificantBitSet
                ? (-306674912 ^ (currentEntry >>> 1))
                : (currentEntry >>> 1);
        }

        // Store the computed CRC32 value for this byte in the lookup table.
        crcTable[byte] = currentEntry;
    }

    // If the environment supports Int32Array, use it for better performance.
    return typeof Int32Array !== 'undefined' ? new Int32Array(crcTable) : crcTable;
};