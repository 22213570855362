/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

export const creatorCanvasProps = {
    props: {
        area: {
            default: "canvas-area",
            type: String
        },
        angle: {
            default: "front",
            type: String
        },
        coords: {
            default: () => ({
                back_x: 0, back_y: 0,
                front_x: 0, front_y: 0,
                height_area: 0, highlight_point_1: 0,
                highlight_point_2: 0
            }),
            type: Object
        },
        edit: {
            default: () => ({}),
            type: Object
        },
        id: {
            default: "canvas-print",
            type: String
        },
        imageLoading: {
            default: false,
            type: Boolean
        },
        guide: {
            default: false,
            type: Boolean
        },
        grid: {
            default: true,
            type: Boolean
        },
        images: {
            default: () => ({
                back: "",
                front: "",
                front_public: "",
                back_public: "",
                front_generic: "",
            }),
            type: Object
        },
        imageId: {
            default: "canvas-image",
            type: String
        },
        milliToPixel: {
            default: 3.7795275591,
            type: Number
        },
        mode: {
            default: "editor",
            type: String
        },
        print: {
            default: () => ({}),
            type: Object
        },
        product: {
            default: () => ({}),
            type: Object
        },
        selected: {
            default: () => ({
                colour: "white",
                size: "l"
            }),
            type: Object
        },
        url: {
            default: "",
            type: String
        },
        isACylindricalProduct: {
            type:Boolean,
            default: false
        }
    },
    data() {
        return {
            imageFiles: { back: "", front: "" },
            loading: false
        }
    },
    mounted() {
        setTimeout(() => {
            this.checkImage("front");
            this.checkImage("back");
        }, 1000);
    },
    computed: {
        canvasCoords() {
            let output = { x: 0, y: 0 };

            if (this.coords[`${this.angle}_x`]) output.x = this.coords[`${this.angle}_x`];
            if (this.coords[`${this.angle}_y`]) output.y = this.coords[`${this.angle}_y`];

            return output;
        },
        canvasPosition() {
            let output = "height: 100%;";

            if ((this.canvasCoords.x || this.canvasCoords.y) && !this.coords.height_area) {
                const x = (this.canvasCoords.x / 100) * this.print.imageScaledPercentage;
                const y = (this.canvasCoords.y / 100) * this.print.imageScaledPercentage;

                output = `left: ${x}px; position: absolute; top: ${y}px`;
            } else if ((this.canvasCoords.x || this.canvasCoords.y) && this.coords.height_area) {
                const y = (this.canvasCoords.y / 100) * this.print.imageScaledPercentage;

                output = `position: absolute; top: ${y}px; width: 100%;`;
            }

            return output;
        },
        guideLines() {
            if (this.mode === "consumer") {
                return false;
            }

            let output = this.guide;

            const sideCompare = this.print.widthConverted > this.print.imageRenderedWidth;

            if (!output && this.mode === "consumer" && sideCompare) {
                output = true;
            }

            return output;
        },
        logoGuideLines() {
            let output = { left: 0, right: 0 };

            if (this.mode === "editor") {
                const percent = ((100 / this.print.widthPrintPixels) * this.print.widthConverted);
    
                if (this.coords.highlight_point_1) {
                    output.left = (parseInt(this.coords.highlight_point_1) / 100) * percent;
    
                    // Offset this by the dashed border being 2 pixels thick and outside border
                    output.left += 4;
                }
    
                if (this.coords.highlight_point_2) {
                    output.right = (parseInt(this.coords.highlight_point_2) / 100) * percent;
    
                    // Offset this by the dashed border being 2 pixels thick and outside border
                    output.right += 4;
                }
            }

            return output;
        },
        safeGuidelines() {
            let output = { height: 0, width: 0 };

            if (this.coords.safe_area_height) {
                const h = this.coords.safe_area_height * this.milliToPixel;

                output.height = (h / 100) * this.print.scaledPercentage;
            }

            if (this.coords.safe_area_width) {
                const w = this.coords.safe_area_width * this.milliToPixel;

                output.width = (w / 100) * this.print.scaledPercentage;
            }

            return output;
        }
    },
    methods: {
        calculateTextPosition(position, id) {
            const text = document.getElementById(id);

            if (text) {
                const width = text.offsetWidth;

                position -= width / 2;
            }

            return position;
        },
        checkImage(side) {
            if (this.images[`${side}_public`]) {
                this.imageFiles[side] = this.images[`${side}_public`];
            } else if (this.images[side] && !this.imageFiles[side]) {
                this.getImage(side);
            }
        },
        getImage(side) {
            this.loading = true;

            const url = this.mode === "editor" ? "product-image" : "fe-images";

            this.$axios.get(`/api/akeneo/${url}`, {
                params: { url: this.images[side] }
            }).then(res => {
                this.imageFiles[side] = `data:image/png;base64,${res.data}`;
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    watch: {
        images() {
            this.checkImage("front");
            this.checkImage("back");
            if (this.mode === "consumer") {
                this.wrapCanvas();
            }
        }
    }
}