
    import { createPreviewOrPrintReadyCanvasBlobOrBase64, createBlobFromCanvas, isImageFullyTransparent } from "@/utils/ImageProcessingUtils";
    import { creatorCanvasProps } from "@/mixins/creator/creatorCanvasProps";
    import { browserDebounce } from "@/mixins/browser/browserDebounce";

    /**
     * @author Jason Curren
     * @description Provides a print/canvas area to configure products
     * ============================================================================================
     */
    export default {
        name: "CreatorCanvas",
        mixins: [creatorCanvasProps, browserDebounce],
        props: {
            doeWeHaveCustomisableText: {
                default: false,
                type: Boolean
            },
            customTextOnConsumerCanvasChanged: {
                default: "",
                type: String
            },
            isACylindricalProduct: {
                default: false,
                type: Boolean
            },
            frontPreviewConsumer: {
                default: "",
                type: String
            },
            backPreviewConsumer: {
                default: "",
                type: String
            },
            frontCache: {
                default: [],
                type: Array
            },
            backCache: {
                default: [],
                type: Array
            },
            productPrintArea: {
                default: {},
                type: Object
            }
        },
        data() {
            return {
                resultingCanvasAsBlob: null,
                customTextFrontOriginalCoordinates: null,
                customTextBackOriginalCoordinates: null
            }
        },
        created() {
            // Set up a debounced version of wrapCanvas.
            if (this.mode === "consumer") {
                this.debouncedWrapCanvas = this.debounce(this.wrapCanvas, 50);
            }
        },
        watch: {
            customTextOnConsumerCanvasChanged() {
                if (this.mode === "consumer") {
                    this.debouncedWrapCanvas();
                }
            },
        },
        methods: {
            changeViewingAngleConsumer(newValue) {
                this.$emit('change-viewing-angle-consumer', newValue);
            },
            async wrapCanvas() {
                let resultingCanvasAsBlob;
                if (this.isACylindricalProduct) {
                    if (this.frontCache.length > 0) {
                        resultingCanvasAsBlob = await this.combineProductImageWithEditorCanvasCylindrical();
                        this.resultingCanvasAsBlob = URL.createObjectURL(resultingCanvasAsBlob);
                    }
                    this.$store.commit("product/UPDATE_CUSTOM_TEXT_FRONT_PREVIEW_IMAGE", resultingCanvasAsBlob);
                    this.loading = false;
                    return;
                }

                if (this.frontCache.length > 0 || this.backCache.length > 0) {
                    resultingCanvasAsBlob = await this.combineProductImageWithEditorCanvas(this.angle);
                    this.resultingCanvasAsBlob = URL.createObjectURL(resultingCanvasAsBlob);
                    if (this.angle === "front") {
                        this.$store.commit("product/UPDATE_CUSTOM_TEXT_FRONT_PREVIEW_IMAGE", resultingCanvasAsBlob);
                    } else if (this.angle === "back") {
                        this.$store.commit("product/UPDATE_CUSTOM_TEXT_BACK_PREVIEW_IMAGE", resultingCanvasAsBlob);
                    }
                    this.loading = false;
                }
            },
            async combineProductImageWithEditorCanvasCylindrical() {
                const productInfo = this.edit;
                const productDiameter = this.images?.imagesFromDifferentAngles?.frontImageData?.productDiameter;
                const wrapXStartingPoint = this.images?.imagesFromDifferentAngles?.frontImageData?.wrapXStartingPoint;

                let necessaryRotationInDegrees = 90;
                let imageData = 'frontImageData';
                if (productInfo.canvas_generate.mainPreviewSide) {
                    switch (productInfo.canvas_generate.mainPreviewSide) {
                        case "leftPreview":
                            necessaryRotationInDegrees = 0;
                            imageData = 'leftImageData';
                            break;
                        case "frontPreview":
                            necessaryRotationInDegrees = 90;
                            imageData = 'frontImageData';
                            break;
                        case "rightPreview":
                            necessaryRotationInDegrees = 180;
                            imageData = 'rightImageData';
                            break;
                        case "backPreview":
                            necessaryRotationInDegrees = 270;
                            imageData = 'backImageData';
                            break;
                        default:
                            necessaryRotationInDegrees = 90;
                            imageData = 'frontImageData';
                            break;
                    }
                }

                if (this.customTextFrontOriginalCoordinates === null) {
                    let customTextObject = this.frontCache.find((canvasObject) => canvasObject.customisable === 1);
                    if (customTextObject) {
                        this.customTextFrontOriginalCoordinates = {
                            left: customTextObject.left,
                            right: customTextObject.left + customTextObject.width,
                            top: customTextObject.top,
                            width: customTextObject.width,
                            originalText : customTextObject.text
                        }
                    }
                }

                return new Promise(async (resolve, reject) => {
                    const sourceCanvas = new Image();
                    let sourceCanvasBlob = await createPreviewOrPrintReadyCanvasBlobOrBase64(
                        this.$fabric,
                        this.frontCache,
                        this.productPrintArea,
                        "consumerPreview",
                        "blob",
                        this.edit.front,
                        this.customTextFrontOriginalCoordinates
                    );
                    
                    // const checkAndRetry = async () => {
                    //     let attempts = 0;
                    //     const maxAttempts = 10; // To avoid infinite loops.
                    //     while (attempts < maxAttempts) {
                    //         const isTransparent = await isImageFullyTransparent(sourceCanvasBlob);

                    //         if (!isTransparent) {
                    //             return sourceCanvasBlob; // Blob is ready.
                    //         }
                    //         this.loading = true;

                    //         attempts++;

                    //         // Wait before retrying.
                    //         await new Promise(resolve => setTimeout(resolve, 100));

                    //         // Try creating the blob again.
                    //         sourceCanvasBlob = await createPreviewOrPrintReadyCanvasBlobOrBase64(
                    //             this.$fabric,
                    //             this.frontCache,
                    //             this.productPrintArea,
                    //             "consumerPreview",
                    //             "blob",
                    //             this.customTextFrontOriginalCoordinates
                    //         );
                    //     }
                    //     throw new Error("Max blob generation retries reached. Blob is still transparent.");
                    // };

                    // try {
                    //     sourceCanvasBlob = await checkAndRetry();
                    // } catch (error) {
                    //     return reject(error);
                    // }
                    sourceCanvas.src = URL.createObjectURL(sourceCanvasBlob);
                    const offScreenCanvas = document.createElement('canvas');
                    const offScreenCtx = offScreenCanvas.getContext('2d');

                    sourceCanvas.onload = () => {

                        const productImage = new Image();
                        // Needed to allow the canvas to be exported as an image.
                        // https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image
                        productImage.crossOrigin = "Anonymous";
                        if (productInfo.canvas_generate.mainPreviewSide) {
                            productImage.src = this.images?.imagesFromDifferentAngles?.[`${imageData}`].imageURL;
                        } else {
                            productImage.src = this.images?.front_public;
                        }

                        productImage.onload = async () => {
                            offScreenCanvas.width = productImage.width;
                            offScreenCanvas.height = productImage.height;
                            offScreenCtx.clearRect(0, 0, offScreenCanvas.width, offScreenCanvas.height);

                            // Set a constant by which to adjust X dimension.
                            const referenceWidth = productImage.width;
                            const scaleFactorWidth = referenceWidth / sourceCanvas.width;

                            const scaledWidth = offScreenCanvas.width;
                            const scaledHeight = this.coords?.height_area;

                            const cylinderRadius = Math.floor(productDiameter / 2);

                            const xOffset = wrapXStartingPoint + cylinderRadius;
                            const yOffset = this.coords?.front_y;

                            const rotationRadians = (0.5 + (-(necessaryRotationInDegrees) / 360) * 2 * Math.PI) + (Math.PI / 2);

                            // Uncomment for transparency (will need if statement)
                            // for (let x = 0; x < scaledWidth; x++) {
                            //     let angle;
                            //     if (productInfo.canvas_generate.mainPreviewSide) {
                            //         angle = (x / scaledWidth) * 2 * Math.PI + Math.PI / 3 + rotationRadians;
                            //     } else {
                            //         angle = (x / scaledWidth) * 2 * Math.PI + Math.PI / 2;
                            //     }
                            //     const xOnCylinder = Math.cos(angle) * cylinderRadius;
                            //     const yOnCylinder = Math.sin(angle) * 16;

                            //     const sourceX = (x / scaleFactorWidth) / (offScreenCanvas.width / referenceWidth);

                            //     if (Math.sin(angle) >= 0) {
                            //         offScreenCtx.globalAlpha = 0.5; // Adjust transparency level
                            //         offScreenCtx.drawImage(sourceCanvas, sourceX, 0, 1, sourceCanvas.height,
                            //                             xOffset + xOnCylinder, yOffset - yOnCylinder, 1.5, scaledHeight);
                            //         offScreenCtx.globalAlpha = 1.0; // Reset to default for the next iteration of for
                            //     }
                            // }
                            offScreenCtx.drawImage(productImage, 0, 0, offScreenCanvas.width, offScreenCanvas.height);
                            for (let x = 0; x < scaledWidth; x++) {
                                let angle;
                                if (productInfo.canvas_generate.mainPreviewSide) {
                                    angle = (x / scaledWidth) * 2 * Math.PI + Math.PI / 3 + rotationRadians;
                                } else {
                                    angle = (x / scaledWidth) * 2 * Math.PI + Math.PI / 2;
                                }
                                const xOnCylinder = Math.cos(angle) * cylinderRadius;
                                const yOnCylinder = Math.sin(angle) * 16;

                                const sourceX = (x / scaleFactorWidth) / (offScreenCanvas.width / referenceWidth);

                                if (Math.sin(angle) < 0) {
                                    offScreenCtx.drawImage(sourceCanvas, sourceX, 0, 1, sourceCanvas.height,
                                                        xOffset + xOnCylinder, yOffset - yOnCylinder, 1.5, scaledHeight);
                                }
                            }

                            let canvasBlob;
                            try {
                                canvasBlob = await createBlobFromCanvas(offScreenCanvas);
                                // console.log(this.roughSizeOfObject(canvasBlob));
                                resolve(canvasBlob);
                            } catch (error) {
                                console.error("Error creating blob:", error);
                            }
                        }
                    }
                });
            },
            async combineProductImageWithEditorCanvas(displayedAngle) {
                if (this.customTextFrontOriginalCoordinates === null) {
                    let customTextObject = this.frontCache.find((canvasObject) => canvasObject.customisable === 1);
                    if (customTextObject) {
                        this.customTextFrontOriginalCoordinates = {
                            left: customTextObject.left,
                            right: customTextObject.left + customTextObject.width,
                            top: customTextObject.top,
                            width: customTextObject.width,
                            scaleX: customTextObject.scaleX,
                            scaleY: customTextObject.scaleY,
                            originalText : customTextObject.text
                        }
                    }
                }

                if (this.backCache.length > 0 && this.customTextBackOriginalCoordinates === null) {
                    let customTextObject = this.backCache.find((canvasObject) => canvasObject.customisable === 1);
                    if (customTextObject) {
                        this.customTextBackOriginalCoordinates = {
                            left: customTextObject.left,
                            right: customTextObject.left + customTextObject.width,
                            top: customTextObject.top,
                            width: customTextObject.width,
                            originalText : customTextObject.text
                        }
                    }
                }

                return new Promise( async (resolve, reject) => {
                    const sourceCanvas = new Image();
                    let sourceCanvasBlob = await createPreviewOrPrintReadyCanvasBlobOrBase64(
                        this.$fabric,
                        displayedAngle == "front" ? this.frontCache : this.backCache,
                        this.productPrintArea,
                        "consumerPreview",
                        "blob",
                        displayedAngle == "front" ? this.edit.front : this.edit.back,
                        displayedAngle == "front" ? this.customTextFrontOriginalCoordinates : this.customTextBackOriginalCoordinates
                    );
                    // const checkAndRetry = async () => {
                    //     let attempts = 0;
                    //     const maxAttempts = 10; // To avoid infinite loops.
                    //     while (attempts < maxAttempts) {
                    //         const isTransparent = await isImageFullyTransparent(sourceCanvasBlob);

                    //         if (!isTransparent) {
                    //             return sourceCanvasBlob; // Blob is ready.
                    //         }
                    //         this.loading = true;

                    //         attempts++;

                    //         // Wait before retrying.
                    //         await new Promise(resolve => setTimeout(resolve, 100));

                    //         // Try creating the blob again.
                    //         sourceCanvasBlob = await createPreviewOrPrintReadyCanvasBlobOrBase64(
                    //             this.$fabric,
                    //             displayedAngle == "front" ? this.frontCache : this.backCache,
                    //             this.productPrintArea,
                    //             "consumerPreview",
                    //             "blob",
                    //             displayedAngle == "front" ? this.customTextFrontOriginalCoordinates : this.customTextBackOriginalCoordinates
                    //         );
                    //     }
                    //     throw new Error("Max blob generation retries reached. Blob is still transparent.");
                    // };

                    // try {
                    //     sourceCanvasBlob = await checkAndRetry();
                    // } catch (error) {
                    //     return reject(error);
                    // }
                    sourceCanvas.src = URL.createObjectURL(sourceCanvasBlob);
                    const offScreenCanvas = document.createElement('canvas');
                    const offScreenCtx = offScreenCanvas.getContext('2d');

                    sourceCanvas.onload = () => {
                        const productImage = new Image();
                        // Needed to allow the canvas to be exported as an image.
                        // https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image
                        productImage.crossOrigin = "Anonymous";
                        productImage.src = this.angle == 'front' ? this.images?.front_public : this.images?.back_public;

                        productImage.onload = async () => {
                            offScreenCanvas.width = productImage.width;
                            offScreenCanvas.height = productImage.height;
                            offScreenCtx.clearRect(0, 0, offScreenCanvas.width, offScreenCanvas.height);
                            offScreenCtx.drawImage(productImage, 0, 0, offScreenCanvas.width, offScreenCanvas.height);

                            let scaleFactor;
                            if (this.coords.height_area) {
                                scaleFactor = this.coords.height_area / sourceCanvas.height;
                                const scaledWidth = Math.round(sourceCanvas.width * scaleFactor);
                                offScreenCtx.drawImage(
                                    sourceCanvas,
                                    this.coords.front_x,
                                    this.coords.front_y,
                                    scaledWidth,
                                    this.coords.height_area
                                );
                            } else {
                                scaleFactor = (offScreenCanvas.width - (this.coords.front_x * 2)) / sourceCanvas.width;
                                const scaledWidth = Math.round(sourceCanvas.width * scaleFactor);
                                const scaledHeight = Math.round(sourceCanvas.height * scaleFactor);
                                offScreenCtx.drawImage(
                                    sourceCanvas,
                                    this.coords.front_x,
                                    this.coords.front_y,
                                    scaledWidth,
                                    scaledHeight
                                );
                            }

                            // Blob conversion
                            let canvasBlob;
                            try {
                                canvasBlob = await createBlobFromCanvas(offScreenCanvas);
                                // console.log(this.roughSizeOfObject(canvasBlob));
                                resolve(canvasBlob);
                            } catch (error) {
                                console.error("Error creating blob:", error);
                            }
                        };

                        productImage.onerror = reject;
                    }
                });
            },
        }
    }
